.common-snackbar {
/*   background-color: white;
  padding: 10px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important; */
}

.error-snackbar {
/*   color: #F53030;
  border: solid 1px #F53030; */
}

.success-snackbar {
/*   color: #1e4620;
  border: solid 1px #1e4620; */
}

.advice-snackbar {
/*   color: #000c56;
  border: solid 1px #000c56; */
}

.action-snackbar {
/*   color: #000c56;
  border: solid 1px #000c56;
  .mat-simple-snackbar-action {
    font-weight: 600 !important;
    color: #000c56;
  } */
}
