@use '@angular/material' as mat;

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "./colors/light-theme.scss";
@import "./colors/dark-theme.scss";
@import "./colors/reset-theme.scss";

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './app/components/snackbar/snackbar.scss';

@mixin custom-components-color($theme) {
  @include styles-reset-theme($theme);
}

.light-theme {
  @include angular-material-color($my-light-theme);
  @include custom-components-color($my-light-theme);
}

.dark-theme {
  @include angular-material-color($my-dark-theme);
  @include custom-components-color($my-dark-theme);
}

body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  color: black;
  margin: 0px;
  padding: 0px;
}

.mat-tooltip {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 5px 0px;
  margin: 0px;
}

/* Para navegadores basados en Blink (Chrome, Edge, etc.) */
::-webkit-scrollbar {
  display: none;
}

/* Para Firefox */
/* Anotación: El siguiente código es necesario para versiones más recientes de Firefox (a partir de Firefox 64) */
* {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

/* Para navegadores basados en Gecko (antiguas versiones de Firefox, Thunderbird, etc.) */
html {
  overflow: -moz-scrollbars-none;
  line-height: 20px;
}
