@import '@angular/material/theming';

@mixin styles-reset-theme($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  shownEntmat-tooltip {
    background-color: mat-color($primary);
    color: mat-color($primary, 25);
  }

  .container {
    background: mat-color($primary, 25);
    color: mat-color($primary);

    .notFoundMess {
      color: mat-color($primary);
    }

    .button {
      color: mat-color($primary, 700);
      background: mat-color($primary, 50);

      &:hover {
        background: mat-color($primary, 100);
      }

      &:active {
        background: mat-color($primary, 200);
      }
    }

  }

  .vjs-poster {
    background-color: mat-color($primary, 25);
  }

  #audio-player-container {
    background: mat-color($primary, 25);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 2px mat-color($primary, 100);
  }

  p,
  .adds {
    color: mat-color($primary, 300);
    background-color: mat-color($primary, 25);
  }

  .description {
    background-color: mat-color($primary, 50) !important;
  }

  #volume-slider::-webkit-slider-runnable-track {
    background: mat-color($primary, 300);
  }

  #volume-slider::-moz-range-track {
    background: mat-color($primary, 300);
  }

  .mute {
    color: mat-color($primary, 300);
  }

  input[type="range"] {
    background: mat-color($primary, 25);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(to right, mat-color($primary, 300) var(--buffered-width), mat-color($primary, 400) var(--buffered-width));
  }

  input[type="range"]::-moz-range-thumb {
    border: 1px solid mat-color($primary, 100);
  }

  input[type="range"]::before {
    background-color: mat-color($primary, 100);
  }

  input[type="range"]::-webkit-slider-thumb {
    border: 1px solid mat-color($primary, 100);
    background-color: mat-color($primary, 200);
  }

  input[type="range"]:active::-webkit-slider-thumb {
    background: mat-color($primary, 100);
  }

  input[type="range"]::-moz-range-track {
    background: linear-gradient(to right, mat-color($primary, 300) var(--buffered-width), mat-color($primary, 400) var(--buffered-width));
  }

  input[type="range"]::-moz-range-progress {
    background-color: mat-color($primary, 100);
  }

  input[type="range"]::-moz-range-thumb {
    border: 1px solid mat-color($primary, 100);
    background-color: mat-color($primary, 25);
  }

  input[type="range"]:active::-moz-range-thumb {
    background: mat-color($primary, 100);
  }

  .above input[type="range"]::before {
    border-left: 2px solid mat-color($primary, 300);
    border-right: 2px solid mat-color($primary, 300);
  }

  video {
    background-color: mat-color($primary, 25);
  }

  .errorIcon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px mat-color($primary, 100);
    border-radius: 50%;
  }

  .keyword {
    color: mat-color($primary, 400);
    border: solid 1px mat-color($primary, 100);
  }

  a {
    text-decoration: none;
    color: mat-color($primary, 400);
  }

}